.mint-btn{
  background: #e52b12;
  font-size: 24px;
  font-family: "Poppins",sans-serif;
  text-transform: uppercase;
  padding: 2px 30px;
  border: 0;
  border-radius: 50px;
  color: #fff;
}
p {
  color: #312010;
  font: 300 20px/31px "Poppins",sans-serif;
}